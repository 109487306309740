import firebase from "firebase"
import {
  useCollection,
  useDocument,
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useEffect } from "react"
import { CircularProgress } from "@material-ui/core"

export const useScreensCollection = () => {
  const screensRef = firebase.firestore().collection("screens")
  const [snapshot, loading, error] = useCollection(screensRef)
  return [snapshot, loading, error]
}

export const useScreenDoc = screenRef => {
  const [snapshot, loading, error] = useDocument(screenRef)

  return [snapshot, loading, error]
}

export const useScreen = screenRef => {
  const [data, loading, error] = useDocumentData(screenRef)
  const screen = data ? data : {}
  return [screen, loading, error]
}
