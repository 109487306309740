import React from 'react'
import {
  useCollection,
  useDocument,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'
import {
  Select,
  MenuItem,
  IconButton,
  Menu,
  Tooltip,
  ListItemIcon,
} from '@material-ui/core'
import { sceneTypes, useSceneType } from '~/components/Scene/SceneType'

const SceneTypePicker = ({ sceneRef, scene: _scene, style, edge }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [scene = _scene, loading, error] = useDocumentData(
    _scene ? undefined : sceneRef,
  )

  const value = scene && scene.type ? scene.type : ''

  const { Icon } = useSceneType(scene)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton style={style} edge={edge} onClick={handleClick}>
        <Tooltip title="Change scene type">
          <Icon />
        </Tooltip>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Array.from(sceneTypes.entries()).map(([sceneType, { Icon, name }]) => (
          <MenuItem
            selected={sceneType === value}
            key={sceneType}
            onClick={() =>
              Promise.resolve(handleClose()).then(() =>
                sceneRef.update({ type: sceneType }),
              )
            }
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )

  // return (
  //   <Select
  //     value={value}
  //     onChange={event => sceneRef.update({ type: event.target.value })}
  //   >
  //     <MenuItem value="" disabled>
  //       Pick type
  //     </MenuItem>
  //     {Array.from(sceneTypes.entries()).map(([sceneType, { Icon, name }]) => (
  //       <MenuItem key={sceneType} value={sceneType}>
  //         {name}
  //       </MenuItem>
  //     ))}
  //   </Select>
  // )
}

export default SceneTypePicker
