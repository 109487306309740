import React from 'react'
import {
  Calendar,
  LinkVariant,
  FileQuestionOutline,
  Image,
  Markdown,
} from 'mdi-material-ui'
import LinkEditor from './SceneEditor/LinkEditor'
import ImageEditor from './SceneEditor/ImageEditor'
import { Tooltip } from '@material-ui/core'

const NotImplementedEditor = () => `Editor not implemented yet`

const types = new Map([
  [
    'link',
    { Icon: LinkVariant, name: 'Website link (iframe)', Editor: LinkEditor },
  ],
  ['image', { Icon: Image, name: 'Image url', Editor: ImageEditor }],
  [
    'markdown',
    { Icon: Markdown, name: 'Markdown', Editor: NotImplementedEditor },
  ],
  [
    'unknown',
    {
      Icon: FileQuestionOutline,
      name: 'Unknown',
      Editor: NotImplementedEditor,
    },
  ],
])

const getSceneType = (scene = {}) => {
  const type = scene.type

  if (types.has(type)) {
    return types.get(type)
  }

  return types.get('unknown')
}

export const useSceneType = scene => {
  return React.useMemo(() => getSceneType(scene), [scene])
}

const SceneType = ({ scene = {} }) => {
  const { Icon, name } = React.useMemo(() => getSceneType(scene), [scene])
  const title = scene.link && scene.link.url ? scene.link.url : 'No link'
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={title}>
        <Icon style={{ width: '13px', height: '13px', marginRight: '5px' }} />
      </Tooltip>{' '}
      {/* {name} */}
    </span>
  )
}

export default SceneType

export { types as sceneTypes }
