import React from 'react'
import firebase from 'firebase'

const playlists = new Map()

export const getPlaylist = (playlistRef, tracks) => {
  if (typeof window !== 'undefined' && !window.playlists) {
    window.playlists = playlists
  }
  if (playlists.has(playlistRef.id)) {
    return playlists.get(playlistRef.id)
  }

  const playlist = new Playlist(playlistRef, tracks)
  playlists.set(playlistRef.id, playlist)

  return playlist
}

export default class Playlist {
  ref = null
  trackMap = new Map()
  constructor(playlistRef, tracks) {
    this.ref = playlistRef
    if (tracks) {
      this.trackMap = new Map(
        tracks.map(({ trackRef }) => [trackRef.id, { trackRef }]),
      )
    }
  }

  get tracks() {
    return Array.from(this.trackMap.values()).map(trackRef => ({ trackRef }))
  }

  addTrack = trackId => {
    const trackRef = firebase
      .firestore()
      .collection('tracks')
      .doc(trackId)

    this.trackMap.set(trackRef.id, { trackRef })
    this.ref.update({
      tracks: firebase.firestore.FieldValue.arrayUnion({
        trackRef,
      }),
    })
  }

  removeTrack = trackId => {
    this.trackMap.delete(trackId)
    this.ref.update({
      tracks: firebase.firestore.FieldValue.arrayRemove({
        trackRef: firebase
          .firestore()
          .collection('tracks')
          .doc(trackId),
      }),
    })
  }
}
