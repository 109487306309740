import React, { useState } from 'react'
import { Popover, List, ListItem, CircularProgress } from '@material-ui/core'
import { useTracks } from '~/store/tracks'

const ScenePicker = ({ onScene, excludeIds = [] }) => {
  const [snapshot = {}, loading, error] = useTracks()
  if (loading) return <CircularProgress />
  const scenes = snapshot.docs
  if (!scenes || scenes.length === 0) return 'No scenes found'

  return (
    <List>
      {scenes
        .filter(s => !excludeIds.includes(s.id))
        .map(scene => (
          <ListItem button onClick={() => onScene(scene)} key={scene.id}>
            {scene.data().name}
          </ListItem>
        ))}
    </List>
  )
}

const SelectScenePopover = ({
  render,
  onScene,
  onOpen: hocOnOpen,
  excludeIds,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const onClose = () => setAnchorEl(null)
  const onOpen = e => {
    if (hocOnOpen) hocOnOpen()
    setAnchorEl(e.target)
  }
  return (
    <>
      {render({ onOpen, onClose, open })}
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        PaperProps={{
          style: {
            maxHeight: 60 * 4.5,
            width: 200,
          },
        }}
      >
        <ScenePicker onScene={onScene} excludeIds={excludeIds} />
      </Popover>
    </>
  )
}

export default SelectScenePopover
