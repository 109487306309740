import { createMuiTheme } from "@material-ui/core"
import { indigo, pink } from "@material-ui/core/colors"

const theme = createMuiTheme({
  palette: {
    type: "light",

    primary: indigo,
    secondary: pink,
  },
})

export default theme
