import React, { useState } from 'react'
import {
  DotsVertical as MoreIcon,
  DeleteForever,
  Delete,
  Textbox as RenameIcon,
  PlaylistPlus,
} from 'mdi-material-ui'
import {
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Typography,
} from '@material-ui/core'
import SelectScenePopover from '~/components/Scene/SelectScenePopover'
import firebase from 'firebase'

export const DeleteItem = ({ docRef }) => {
  const [deleting, setDeleting] = useState(false)
  return (
    <MenuItem onClick={() => (deleting ? docRef.delete() : setDeleting(true))}>
      <ListItemIcon>{deleting ? <DeleteForever /> : <Delete />}</ListItemIcon>
      <Typography variant="inherit">
        {deleting ? 'Are you sure?' : 'Delete'}
      </Typography>
    </MenuItem>
  )
}

const ActionPopover = ({ screenRef, screen = {}, edge }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const onClose = () => setAnchorEl(null)
  const onOpen = e => setAnchorEl(e.target)
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 4.5,
            width: 200,
          },
        }}
      >
        <MenuItem
          onClick={() =>
            Promise.resolve(onClose())
              .then(() =>
                window.prompt('Rename screen', screen.name || 'Untitled'),
              )
              .then(name => name && screenRef.update({ name }))
          }
        >
          <ListItemIcon>
            <RenameIcon />
          </ListItemIcon>
          <Typography variant="inherit">Rename</Typography>
        </MenuItem>
        <SelectScenePopover
          excludeIds={
            screen && screen.tracks ? screen.tracks.map(ref => ref.id) : null
          }
          onScene={scene =>
            screenRef.update({
              tracks: firebase.firestore.FieldValue.arrayUnion(scene.ref),
            })
          }
          render={({ onOpen }) => (
            <MenuItem onClick={onOpen}>
              <ListItemIcon>
                <PlaylistPlus />
              </ListItemIcon>
              <Typography variant="inherit">Add scene</Typography>
            </MenuItem>
          )}
        />
        <DeleteItem docRef={screenRef} />
      </Menu>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="primary"
        edge={edge}
        onClick={onOpen}
      >
        <MoreIcon />
      </IconButton>
    </>
  )
}

export default ActionPopover
