import React, { useState } from 'react'
import { useScreensCollection } from '../../store/screens'
import ScreenCard from '../Screen/Card'
import TracksToolbar from '../Scenes/Toolbar'
import {
  Grid,
  Container,
  CircularProgress,
  Button,
  Typography,
} from '@material-ui/core'
import firebase from 'firebase'
import { DragDropContext } from 'react-beautiful-dnd'
import { useMap } from 'react-use'

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
]

const GridItem = ({ children }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
    {children}
  </Grid>
)

const CreateItem = () => (
  <GridItem>
    <Button
      onClick={() =>
        firebase
          .firestore()
          .collection('screens')
          .add({ name: 'New' })
      }
    >
      Create
    </Button>
  </GridItem>
)

const getScreenTracks = screenId =>
  firebase
    .firestore()
    .collection('screens')
    .doc(screenId)
    .get({ source: 'cache' })
    .then(doc => doc.data().tracks)

const ScreensGallery = ({ tracks, docs }) => {
  const [tracksSnapshots, setTrackSnapshots] = useState({})

  const handleDragStart = async ({ source }) => {
    // tracksSnapshotsUtils.reset()
    const tracklists = await Promise.all(
      docs.map(doc =>
        getScreenTracks(doc.ref.id).then(tracks => ({
          tracks,
          droppableId: doc.ref.id,
        })),
      ),
    )
    let map = tracklists.reduce(
      (obj, { tracks, droppableId }) => ({ ...obj, [droppableId]: tracks }),
      {},
    )
    setTrackSnapshots(map)

    // setTrackSnapshots({
    //   [source.droppableId]: await getScreenTracks(source.droppableId),
    // })
  }

  const handleDragEnd = async ({
    draggableId,
    source,
    destination,
    combine,
  }) => {
    if (!destination) {
      console.warn('no dest')
      setTrackSnapshots({})
      return
    }
    if (source.droppableId === destination.droppableId) {
      const prevTracks = (tracksSnapshots[source.droppableId]
        ? tracksSnapshots[source.droppableId]
        : await getScreenTracks(source.droppableId)
      ).filter(trackRef => trackRef.id !== draggableId)

      const tracksCol = firebase.firestore().collection('tracks')
      const sourceTrack = tracksCol.doc(draggableId)

      const nextTracks = insert(prevTracks, destination.index, sourceTrack)

      setTrackSnapshots({ [source.droppableId]: nextTracks })

      const screensCol = firebase.firestore().collection('screens')
      const sourceRef = screensCol.doc(source.droppableId)

      sourceRef
        .update({
          tracks: nextTracks,
        })
        .then(() => setTrackSnapshots({}))

      return
    }
    const nextSourceTracks = tracksSnapshots[source.droppableId].filter(
      trackRef => trackRef.id !== draggableId,
    )

    const tracksCol = firebase.firestore().collection('tracks')
    const trackRef = tracksCol.doc(draggableId)

    const destinationTracks = tracksSnapshots[destination.droppableId]
    const nextDestinatonTracks = insert(
      destinationTracks,
      destination.index,
      trackRef,
    )

    setTrackSnapshots({
      [destination.droppableId]: nextDestinatonTracks,
      [source.droppableId]: nextSourceTracks,
    })

    const screensCol = firebase.firestore().collection('screens')
    const sourceRef = screensCol.doc(source.droppableId)

    const destinationRef = screensCol.doc(destination.droppableId)

    Promise.all([
      destinationRef.update({
        tracks: nextDestinatonTracks,
      }),
      sourceRef.update({
        tracks: nextSourceTracks,
      }),
    ]).then(() => setTrackSnapshots({}))
  }

  // get the value from the doc
  if (docs) {
    const cards = docs.map(doc => (
      <GridItem key={doc.ref.id}>
        <ScreenCard
          doc={doc}
          tracks={tracksSnapshots[doc.ref.id]}
          screenRef={doc.ref}
        />
      </GridItem>
    ))
    return (
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <Container fixed>
          <Grid container spacing={4} justify="flex-start" alignItems="stretch">
            {cards}
            {/* {cards.length < 5 && <CreateItem />} */}
          </Grid>
        </Container>
      </DragDropContext>
    )
  }

  return null
}

export default ({ tracks }) => {
  const [snapshot, loading] = useScreensCollection()
  if (loading && (!snapshot || !snapshot.docs)) return <CircularProgress />
  const docs = snapshot.docs

  return (
    <>
      {/* <TracksToolbar /> */}
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={10}>
          <div
            css={{
              padding: '0 24px',
              marginTop: '24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4">Screens</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                firebase
                  .firestore()
                  .collection('screens')
                  .add({ name: 'Untitled screen', tracks: [] })
              }
            >
              Add screen
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <ScreensGallery docs={docs} tracks={tracks} />
        </Grid>
      </Grid>
    </>
  )
}
