import React, { useState } from 'react'
import {
  DotsVertical as MoreIcon,
  DeleteForever,
  Delete,
  Textbox as RenameIcon,
  Buffer as SceneIcon,
} from 'mdi-material-ui'
import {
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Typography,
} from '@material-ui/core'
import firebase from 'firebase'
import { Link } from '@reach/router'

export const DeleteItem = ({ docRef, onDelete }) => {
  const [deleting, setDeleting] = useState(false)
  return (
    <MenuItem
      onClick={() =>
        deleting ? (onDelete ? onDelete() : docRef.delete()) : setDeleting(true)
      }
    >
      <ListItemIcon>{deleting ? <DeleteForever /> : <Delete />}</ListItemIcon>
      <Typography variant="inherit">
        {deleting ? 'Are you sure?' : 'Delete'}
      </Typography>
    </MenuItem>
  )
}

const ActionPopover = ({ edge, playlistRef, trackRef }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const onClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
  }
  const onOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.target)
  }
  return (
    <>
      <IconButton
        color="primary"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        edge={edge}
        onClick={onOpen}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 4.5,
            width: 200,
          },
        }}
      >
        <Link
          to={`/scenes/${trackRef.id}`}
          onClick={() => setAnchorEl(null)}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem>
            <ListItemIcon>
              <SceneIcon />
            </ListItemIcon>
            <Typography variant="inherit">Edit scene</Typography>
          </MenuItem>
        </Link>
        <MenuItem
          onClick={event =>
            Promise.resolve(onClose(event))
              .then(() =>
                firebase
                  .firestore()
                  .collection('tracks')
                  .doc(trackRef.id)
                  .get(),
              )
              .then(trackDoc =>
                window.prompt(
                  'Rename track',
                  trackDoc.data().name || 'Untitled',
                ),
              )
              .then(name => name && trackRef.update({ name }))
          }
        >
          <ListItemIcon>
            <RenameIcon />
          </ListItemIcon>
          <Typography variant="inherit">Rename</Typography>
        </MenuItem>
        <DeleteItem
          onDelete={async () => {
            if (playlistRef) {
              await playlistRef.update({
                tracks: firebase.firestore.FieldValue.arrayRemove(trackRef),
              })
            }
            return trackRef.delete()
          }}
        />
      </Menu>
      {/* <Popover anchorEl={anchorEl} open={open} onClose={onClose}>
        <List>
          <ListItem button onClick={() => screenRef.delete()}>
            <ListItemIcon>
              <DeleteForever />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      </Popover>
      <IconButton onClick={onOpen}>
        <MoreIcon />
      </IconButton> */}
    </>
  )
}

export default ActionPopover
