import { firebaseConfig } from './constants'
import firebase from 'firebase'

export default () => {
  if (typeof window !== 'undefined') {
    firebase.initializeApp(firebaseConfig)
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

    firebase
      .firestore()
      .enablePersistence({ synchronizeTabs: false })
      // .enablePersistence({ synchronizeTabs: true })
      .catch(function(err) {
        if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          console.warn(
            'Multiple tabs open, persistence can only be enabled in one tab at a a time.',
          )
        } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.warn('Firestore persistance not implemented')
        }
      })
  }
}
