import React, { useState } from 'react'
import { useScreen } from '~/store/screens'
import firebase from 'firebase'
import { useTrack } from '~/store/tracks'
import useInterval from '~/lib/useInterval'

const ScenePlayer = ({ scene, onLoad }) => {
  if (scene.type === 'link') {
    if (!scene.link || !scene.link.url) {
      return 'Missing link data'
    }
    const url = scene.link.url

    return (
      <iframe
        src={url}
        style={{ width: '100vw', height: '100vh', border: 'none' }}
        onLoad={() => onLoad()}
      />
    )
  }
  return 'Not supported yet'
}

const ScreenPlayer = ({ screenId }) => {
  const ref = firebase
    .firestore()
    .collection('screens')
    .doc(screenId)
  const [screen] = useScreen(ref)
  const [index, setIndex] = useState(0)
  const [ms, setMs] = useState(null)

  const trackRefs = screen && screen.tracks ? screen.tracks : []
  useInterval(() => setIndex(index > trackRefs.length ? 0 : index + 1), ms)

  const [scene] = useTrack(trackRefs[index])

  if (scene && scene.type) {
    return <ScenePlayer scene={scene} onLoad={() => setMs(5000)} />
  }

  return 'Not working'
}

export default ScreenPlayer
