import React from 'react'
import { CircularProgress, InputAdornment } from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { CheckCircle as SavedIcon } from 'mdi-material-ui'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { green } from '@material-ui/core/colors'

const LinkEditor = ({ sceneRef, open }) => {
  const [scene = {}, loading, error] = useDocumentData(sceneRef)
  if (loading) return <CircularProgress />
  if (scene.type !== 'link') {
    return `Wrong type ${scene.type}`
  }

  return (
    <Form
      initialValues={scene.link}
      onSubmit={link => sceneRef.update({ link })}
      render={({ handleSubmit, pristine, submitSucceeded, valid, values }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Field
            placeholder="https://google.com/"
            label="URL"
            name="url"
            component={TextField}
            variant="outlined"
            color="primary"
            fullWidth
            required
            InputProps={
              submitSucceeded || (pristine && valid && values.url)
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <SavedIcon style={{ color: green[700] }} />
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
        </form>
      )}
    />
  )
}

export default LinkEditor
