import React from 'react'
import { Link } from '@reach/router'

// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: 'active' } : null
}

const ExactNavLink = props => <Link getProps={isActive} {...props} />

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: 'active' } : null
}

const PartialNavLink = props => <Link getProps={isPartiallyActive} {...props} />

export { PartialNavLink, ExactNavLink }
