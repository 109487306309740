import React from 'react'
import {
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Collapse,
  ClickAwayListener,
  Button,
} from '@material-ui/core'
import { pink, green } from '@material-ui/core/colors'
import { useTracks } from '~/store/tracks'
import { useSceneType } from '~/components/Scene/SceneType'
import ActionPopover from '../Scene/ActionPopover'
import SceneTypePicker from '../Scene/SceneTypePicker'
import firebase from 'firebase'
import { Link, navigate } from '@reach/router'
import { DurationEditorPopover } from '../Scene/SceneEditor/DurationEditor'

const Item = ({ title, trackRef, open, type, scene }) => {
  const { Icon, Editor } = useSceneType({ type })
  return (
    <Grid item xs={12} md={12}>
      <Link
        onClick={open ? event => event.preventDefault() : null}
        to={open ? '/scenes' : `/scenes/${trackRef.id}`}
        replace
        style={{ textDecoration: 'none', cursor: open ? 'inherit' : 'pointer' }}
      >
        <Card
          className={open ? 'open' : ''}
          elevation={1}
          css={{
            minHeight: '62px',
            padding: '0 24px',
            '&:hover, &.open': {
              boxShadow:
                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
              // '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              // '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          }}
        >
          <Grid container alignItems="center" style={{ minHeight: '62px' }}>
            <Grid item xs={1}>
              {trackRef && (
                <SceneTypePicker
                  edge="start"
                  sceneRef={trackRef}
                  style={{ color: green[700] }}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={2}>
              <DurationEditorPopover scene={scene} sceneRef={trackRef} />
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={1} align="right">
              <ActionPopover trackRef={trackRef} edge="end" />
            </Grid>
          </Grid>

          <Collapse timeout={{ enter: 350, exit: 350 }} in={open}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: '62px',
                paddingBottom: '24px',
              }}
            >
              <Editor sceneRef={trackRef} open={open} />
            </div>
          </Collapse>
        </Card>
      </Link>
    </Grid>
  )
}

const ScenesList = ({ sceneId }) => {
  const [snapshot, loading, error] = useTracks()
  if (error) return console.log(error)
  return (
    <Container fixed style={{ height: '100vh' }}>
      <Grid
        container
        justify="center"
        alignItems="center"
        wrap="wrap"
        style={{ minHeight: '100%' }}
      >
        <Grid item xs>
          <Grid
            container
            style={{ minHeight: '400px' }}
            spacing={2}
            justify="center"
          >
            <Grid item xs={12} md={10}>
              <div
                css={{
                  padding: '0 24px',
                  marginTop: '24px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4">Scenes</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    firebase
                      .firestore()
                      .collection('tracks')
                      .add({ name: 'Untitled scene', type: 'unknown' })
                      .then(docRef => navigate(`/scenes/${docRef.id}`))
                  }
                >
                  New scene
                </Button>
              </div>
            </Grid>
            <ClickAwayListener
              mouseEvent={sceneId ? 'onClick' : false}
              touchEvent={sceneId ? 'onTouchEnd' : false}
              onClickAway={() => navigate('/scenes')}
            >
              <Grid item xs={12} md={10}>
                <Grid
                  container
                  style={{ minHeight: '400px', height: '100%' }}
                  spacing={2}
                  justify="center"
                >
                  {!loading &&
                    snapshot.docs
                      .map(trackDoc => ({ track: trackDoc.data(), trackDoc }))
                      .map(({ track, trackDoc }) => (
                        <Item
                          title={track.name}
                          type={track.type}
                          scene={track}
                          trackRef={trackDoc.ref}
                          key={trackDoc.ref.id}
                          open={trackDoc.ref.id === sceneId}
                        />
                      ))}
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ScenesList
