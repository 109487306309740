import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  CardActions,
  Button,
  ListItemIcon,
  Divider,
  Tooltip,
} from '@material-ui/core'
import firebase from 'firebase'
import { useScreenDoc, useScreen } from '../../../store/screens'
import { DotsVertical as MoreIcon, OpenInApp } from 'mdi-material-ui'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import ActionPopover from '../ActionPopover'
import Playlist from '../../Playlist'
import { Link } from '@reach/router'
const ScreenSize = ({ screen, screenRef }) => {
  return (
    <Form
      onSubmit={size =>
        Promise.all([
          screenRef.update({ size }),
          new Promise(resolve => setTimeout(resolve, 500)),
        ])
      }
      initialValues={{ width: '', height: '', ...screen.size }}
      validate={values => {
        const errors = {}

        if (values.height && !Number.isInteger(parseInt(values.height))) {
          errors.height = 'Not valid'
        }

        return errors
      }}
      render={({ handleSubmit, submitting, submit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="height"
              label="height"
              variant="outlined"
              type="number"
              component={TextField}
              disabled={submitting}
            />
            <Field
              name="width"
              label="width"
              variant="outlined"
              type="number"
              component={TextField}
              disabled={submitting}
            />
            <button type="submit" style={{ display: 'none' }}>
              save
            </button>
          </form>
        )
      }}
    />
  )
}

const ScreenCard = ({ name, screenRef, tracks }) => {
  const [screen = {}, loading, error] = useScreen(screenRef)
  if (loading && (!tracks && !screen.tracks)) return 'Loading'
  const trackCount = (tracks || screen.tracks || []).length
  return (
    <Card
      color="primary"
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        title={screen.name}
        // subheader={`${trackCount} scenes`}
        action={
          <div style={{ marginRight: '-5px', height: '32px' }}>
            <Tooltip title="Preview">
              <Link to={`/screens/${screenRef.id}`}>
                <IconButton color="secondary">
                  <OpenInApp />
                </IconButton>
              </Link>
            </Tooltip>
            <ActionPopover screen={screen} screenRef={screenRef} />
          </div>
        }
      />
      <Divider />
      <div style={{ position: 'relative', flex: 1 }}>
        <Playlist playlistRef={screenRef} tracks={tracks || screen.tracks} />
      </div>
      {/* <CardContent> */}
      {/* {!loading && <ScreenSize screenRef={screenRef} screen={screen} />} */}
      {/* </CardContent> */}

      <CardActions>
        {/* <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={() =>
            firebase
              .firestore()
              .collection('tracks')
              .add({ name: '' })
              .then(trackRef =>
                screenRef.update({
                  tracks: firebase.firestore.FieldValue.arrayUnion(trackRef),
                }),
              )
          }
        >
          Add
        </Button> */}
      </CardActions>
    </Card>
  )
}

export default ScreenCard
