import React from 'react'
import { indigo } from '@material-ui/core/colors'
import { ImageFilterHdr } from 'mdi-material-ui'
import { Paper } from '@material-ui/core'
import { Link } from '@reach/router'
import Links from './Links'
import { ExactNavLink } from './MatchLink'

const Logo = () => (
  <div
    css={{
      width: '100%',
      userSelect: 'none',

      '>a': {
        textDecorationLine: 'none',
        transition: 'background-color 300ms ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: SIDEBAR_WIDTH,
        color: indigo[900],
        background: 'rgba(255,255,255,.95)',
        '&:hover, &.active': {
          background: '#FFF',
          // color: indigo[500],
        },
      },
      svg: {
        width: '32px',
        height: '32px',
        marginTop: '5px',
        // marginBottom: '-5px',
      },
    }}
  >
    <ExactNavLink to="/">
      <ImageFilterHdr />
      <span
        style={{
          marginBottom: '10px',
        }}
      >
        Screens
      </span>
    </ExactNavLink>
  </div>
)

export const SIDEBAR_WIDTH = '82px'
const Sidebar = () => (
  <Paper
    square
    elevation={8}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: SIDEBAR_WIDTH,
      background: indigo[900],
      color: 'white',
      height: '100%',
    }}
  >
    <Logo />
    <Links />
  </Paper>
)

const SidebarLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div style={{ paddingLeft: SIDEBAR_WIDTH, minHeight: '100vh' }}>
        {children}
      </div>
    </>
  )
}

export default SidebarLayout
