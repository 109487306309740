import React, { Suspense } from 'react'

import Helmet from 'react-helmet'
import ScreensGallery from '../components/Screens/Gallery'
import ScreenPlayer from '../components/Screen/Player'
import initFirebase from '../lib/initFirebase'
import ErrorBoundary from '../components/ErrorBoundary'
import { CssBaseline } from '@material-ui/core'
import { Router } from '@reach/router'
import { ThemeProvider } from '@material-ui/styles'
import theme from '~/lib/theme'
import SidebarLayout from '~/components/Layout/SidebarLayout'
import ScenesList from '~/components/Scenes/ScenesList'
import Scenes from '~/components/Scenes'

function App() {
  React.useState(() => initFirebase())
  if (typeof window === 'undefined') {
    return (
      <>
        <Helmet title="Screens">
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Helmet>
        <CssBaseline />
      </>
    )
  }

  return (
    <>
      <Helmet title="Screens">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={'Loading app'}>
            <Router>
              <SidebarLayout path="/">
                <ScreensGallery path="/" />
                <Scenes path="/scenes">
                  <ScenesList path="/"></ScenesList>
                  <ScenesList path=":sceneId" />
                </Scenes>
              </SidebarLayout>
              <ScreenPlayer path="/screens/:screenId" />
            </Router>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  )
}

export default App
