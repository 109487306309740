import firebase from 'firebase'
import {
  useCollection,
  useDocument,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'

export const useTrack = trackRef => {
  const [data, loading, error] = useDocumentData(trackRef)
  const track = data ? data : {}
  return [track, loading, error]
}

export const useTracks = () => {
  const tracksRef = firebase
    .firestore()
    .collection('tracks')
    .where('name', '>', '')
    .orderBy('name', 'desc')

  const [snapshot, loading, error] = useCollection(tracksRef)
  return [snapshot, loading, error]
}
