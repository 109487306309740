import React, { useState } from 'react'
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Popover,
} from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import {
  CheckCircle as SavedIcon,
  Timelapse as DurationIcon,
} from 'mdi-material-ui'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { green, yellow } from '@material-ui/core/colors'
// import PieChart from 'react-minimal-pie-chart'

export const DurationEditorPopover = ({ sceneRef, scene: _scene }) => {
  const [scene = _scene, loading, error] = useDocumentData(
    _scene ? undefined : sceneRef,
  )
  const [anchorEl, setAnchorEl] = useState(null)
  if (loading) return <CircularProgress />
  const open = Boolean(anchorEl)
  const onClose = e => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    setAnchorEl(null)
  }
  const onOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.target)
  }
  return (
    <>
      <IconButton onClick={onOpen}>
        <DurationIcon style={{ color: yellow[700] }} />
        {/* <PieChart
          style={{ width: '22px' }}
          totalValue={60}
          startAngle={270}
          background={yellow[100]}
          data={[
            { title: 'Three', value: scene.duration || 30, color: yellow[700] },
          ]}
        /> */}
      </IconButton>

      {scene.duration ? (
        <span onClick={onOpen} style={{ color: yellow[700], fontSize: '12px' }}>
          {scene.duration}s
        </span>
      ) : null}

      <Popover open={open} anchorEl={anchorEl} onClose={onClose}>
        <div style={{ padding: '12px' }}>
          <DurationEditor sceneRef={sceneRef} scene={scene} onClose={onClose} />
        </div>
      </Popover>
    </>
  )
}

const DurationEditor = ({ sceneRef, open, scene: _scene, onClose }) => {
  const [scene = _scene, loading, error] = useDocumentData(
    _scene ? undefined : sceneRef,
  )
  if (loading) return <CircularProgress />

  return (
    <Form
      initialValues={{ duration: scene.duration }}
      onSubmit={({ duration }) => sceneRef.update({ duration }).then(onClose)}
      render={({ handleSubmit, pristine, submitSucceeded, valid, values }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Field
            placeholder="5"
            label="Duration (seconds)"
            name="duration"
            type="number"
            component={TextField}
            variant="outlined"
            color="primary"
            fullWidth
            required
            autoFocus
            InputProps={
              submitSucceeded || (pristine && valid && values.url)
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <SavedIcon style={{ color: green[700] }} />
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
        </form>
      )}
    />
  )
}

export default DurationEditor
