import React from 'react'

import { Buffer, CalendarOutline } from 'mdi-material-ui'
import { ExactNavLink, PartialNavLink } from './MatchLink'
import { SIDEBAR_WIDTH } from '.'

const NavLink = ({ to, title, Icon, exact }) => {
  const LinkComponent = exact ? ExactNavLink : PartialNavLink

  return (
    <LinkComponent to={to}>
      <Icon />
      {title}
    </LinkComponent>
  )
}

const Links = () => {
  return (
    <div
      css={{
        a: {
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          padding: '12px 0',
          fontSize: '13px',
          minHeight: '70px',
          transition: 'background-color 300ms ease',
          '&:hover': {
            background: 'rgba(255,255,255,.05)',
          },
          margin: '6px 0',
        },
        'a.active': {
          background: 'rgba(255,255,255,.1)',
          // fontWeight: '600',
          // svg: { opacity: 1 },
        },
        svg: {
          color: 'white',
          width: '24px',
          height: '24px',
          marginBottom: '4px',
          // opacity: 0.8,
        },
        margin: '6px 0',
      }}
    >
      <NavLink to="/scenes" Icon={Buffer} title="Scenes" />
      <NavLink to="/schedule" Icon={CalendarOutline} title="Schedule" />
    </div>
  )
}

export default Links
