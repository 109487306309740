import { Droppable, Draggable } from 'react-beautiful-dnd'
import React, { memo } from 'react'
import { usePlaylist } from '~/store/playlists'
import { DeleteItem } from '../Screen/ActionPopover'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@material-ui/core'
import { useTrack } from '~/store/tracks'
import ActionPopover from '../Scene/ActionPopover'
import { Drag } from 'mdi-material-ui'
import SceneType, { useSceneType } from '../Scene/SceneType'
import { DurationEditorPopover } from '../Scene/SceneEditor/DurationEditor'
import { yellow } from '@material-ui/core/colors'

const getBackgroundColor = snapshot => {
  // Giving isDraggingOver preference
  if (snapshot.isDraggingOver) {
    return '#fff8e1'
  }

  // If it is the home list but not dragging over
  if (snapshot.draggingFromThisWith) {
    return '#e0f7fa'
  }

  // Otherwise use our default background
  return 'transparent'
}

const TrackListItem = memo(
  ({ trackRef }) => {
    const [track = {}, loading] = useTrack(trackRef)
    return (
      <>
        <ListItemText
          primary={track.name || trackRef.id}
          secondary={
            <span style={{ display: 'flex' }}>
              <SceneType scene={track} />
              {track.duration ? (
                <span style={{ color: yellow[700], fontSize: '12px' }}>
                  {track.duration}s
                </span>
              ) : null}
            </span>
          }
        />
      </>
    )
  },
  (prev, next) => prev.trackRef.id === next.trackRef.id,
)

function Track({ trackRef, index, playlistRef }) {
  // Renders out a draggable student

  return (
    <Draggable draggableId={trackRef.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <div style={{ padding: '5px 0' }}>
              <div
                css={{ '&:hover': { background: 'rgba(0,0,0,.05)' } }}
                style={{
                  backgroundColor: snapshot.isDragging ? '#ffffff' : undefined,
                  transition: 'all 500ms ease',
                  color: 'black',
                  boxShadow: snapshot.isDragging
                    ? '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)'
                    : 'none',
                }}
              >
                <ListItem
                  component="div"
                  ContainerComponent="div"
                  {...provided.dragHandleProps}
                >
                  <Drag style={{ marginRight: '15px' }} />

                  <TrackListItem trackRef={trackRef} />

                  <ListItemSecondaryAction>
                    <ActionPopover
                      edge="end"
                      trackRef={trackRef}
                      playlistRef={playlistRef}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

// do not re-render if the students list reference has not changed
const InnerList = React.memo(
  function InnerList(props) {
    if (!Array.isArray(props.tracks)) return null
    return props.tracks.map((trackRef, index) => (
      <Track
        key={trackRef.id}
        trackRef={trackRef}
        index={index}
        playlistRef={props.playlistRef}
      />
    ))
  },
  (prev, next) => {
    if (prev.playlistRef !== next.playlistRef) {
      return false
    }
    if (prev.tracks === next.tracks) {
      return true
    }
    if (!prev.tracks || !next.tracks) {
      return false
    }
    if (prev.tracks.length === next.tracks.length) {
      if (next.tracks.every(({ id }, i) => prev.tracks[i].id === id)) {
        return true
      }
    }

    return false
  },
)

const Playlist = ({ playlistRef, tracks }) => {
  if (!playlistRef) return 'Missing playlistRef'
  return (
    <Droppable droppableId={playlistRef.id} type="TRACK">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            backgroundColor: getBackgroundColor(snapshot),
            minHeight: '200px',
            height: '100%',
            transition: 'background-color 500ms ease',
          }}
          {...provided.droppableProps}
        >
          <InnerList tracks={tracks} playlistRef={playlistRef} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default Playlist
