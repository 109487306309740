import React from "react"
import { Toolbar, Button, Grid } from "@material-ui/core"

const TracksToolbar = () => {
  return (
    <Toolbar color="secondary">
      <Grid container justify="center">
        <Grid item xs={2}>
          <Grid container justify="center">
            <Button>Create</Button>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default TracksToolbar
